// i18n.js
export const messages = {
    EN: {
      dd:{
        fund:"Fund",
        tofund:"To be Funded",
        male:"Male",
        female:"Female",
        mining:"Mining",
        agre:"Agriculture",
        water:"Water Works",
        const:"Construction",
        dm:"Digital Marketing",
        v:"Vehicle",
        ap: 'Apartment',
        re: 'Realstate',
        e: 'Energy',
        f: 'Finance',
        t: 'Technology', 
        r: 'Retail',
        m: 'Manufacturing',
        o: 'Other'
      },
      app: {
        title: 'GIBIG Mutual Fund Registration',
        about: 'About',
        contact: 'Contact',
        submit: 'Submit',
        reset: 'Reset',
      },
      registration: {
        title: 'Register for Mutual Fund',
        personal: 'Personal',
        company: 'Company',
        welcome:'Welcome to Golden International Business and Investment Group Mutual Fund Registration System.',
        desc:"Where you can easily register as funders or request for fund!"
      },
      personal: {
        registration: 'Personal Registration',
        registration_id: 'Registration ID',
        name: 'Name',
        look:"I look forward to",
        fname:"First Name",
        mname:"Middle Name",
        lname:"Last Name",
        age:"Age",
        gender:"Gender",
        zadress:"Zone",
        waddress:"Woreda",
        kaddress:"Kebelle",
        pnumber:"Phone Number",
        email:"Email",
        photo:"Photo",
        sdoc:"Supportive Document (Please merge all your documents in one pdf(your ID card, business plan  ))",
        sector:"Sector for Mutual Fund",
        sfname:"Surety First Name",
        smname:"Surety Middle Name",
        slname:"Surety Last Name",
        szone:"Surety Zone",
        sworeda:"Surety Woreda",
        skebelle:" Surety Kebelle",
        spnumber:"Surety Phone Number",
        agree:"I understand and agree to the following:",
        frequest:"Terms and Policies for Fund Request" ,
        funder:"Terms and Policies for Funders",
        cname:"Company Name",
        ceo:"CEO",
        osector:"Specify Sector"

      },
      company: {
        registration: 'Company Registration',
        registration_id: 'Registration ID',
        name: 'Company Name',
        sector: 'Sector'
      },
      funding: {
        label: 'I look forward to'
      },

      rules :{
        require:"required",
        agree:"You must agree to continue.",
        pn:'Must be a positive number.',
        e:'E-mail must be valid.',
        p:'Phone number must be valid.'
      },
      msg:{
        scs:"Registration successful!, we will be in touch with you shortly.",
        e:"Email is already registered. Please use other email address.",
        err:"An unexpected error occurred. Please try again later.",

      }
    },
    ትግ: {
      dd:{
        fund:"ሓጋዚ",
        tofund:"ተሓጋዚ",
        male:"ተባዕታይ",
        female:"ኣንስተይቲ",
        mining:"ዕደና",
        agre:"ግብርና",
        water:"ስራሕቲ ማይ",
        const:"ኮንስትራክሽን",
        dm:"ዲጂታላዊ ማርኬቲንግ",
        v:"ተሽከርካሪ",
        ap: 'ኣፓርታማ',
        re: 'ሪልስቴት',
        e: 'ጸዓት',
        f: 'ፋይናንስ',
        t: 'ቴክኖሎጂ',
        r: 'ችርቻሮ',
        m: 'ማኑፋክቸሪንግ',
        o: 'ካልእ',
      },
      app: {
        title: 'GIBIG ምዝገባ ሓባራዊ ተረባሕነት',
        about: 'ብዝዕባ',
        contact: 'መርከቢ',
        submit: 'ኣእትው',
        reset: 'ይፅረጉ',
      },
      registration: {
        title: 'ንሓባራዊ ተረባሕነት ይመዝገቡ።',
        personal: 'ብውልቂ',
        company: 'ብትካል',
        welcome:"እንኳዕ ናብ ማሕበር ኣክስዮን ዓለምለኻዊ ጉጅለ ንግድን ወፈራን ጎልደን  ናይ ሓባራዊ ተረባሕነት  መመዝገቢ ቅጥዒ ብደሓን መፃእኹም።",
        desc:"ብቐሊሉ ከም መወልቲ ክትምዝገቡሉ ወይ ፋንድ ክትሓቱሉ እትኽእሉ!"
      },
      personal: {
        registration: 'ምዝገባ',
        registration_id: 'ምዝገባ መለለዪ ቁጽሪ',
        name: 'ስም',
        look:"ብሃንቀውታ ይጽበዮ ኣለኹ",
        fname:"ቀዳማይ ስም",
        mname:"ማእከላይ ስም",
        lname:"መወዳእታ ስም",
        age:"ዕድመ",
        gender:"ጾታ",
        zadress:"ዞባ",
        waddress:"ወረዳ",
        kaddress:"ቀበሌ",
        pnumber:"ቁጽሪ ተሌፎን",
        email:"ኢመይል",
        photo:"ስእሊ",
        sdoc:"ደጋፊ ሰነድ (በጃኹም ኩሎም ሰነዳትኩም ኣብ ሓደ pdf(መለለዪ ካርድኹም፣ ትልሚ ቢዝነስኩም )) ኣወሃሂድኩም",
        sector:"ዘፈር ንሓባራዊ ፋንድ",
        sfname:"ዋሕስ ቀዳማይ ስም",
        smname:"ዋሕስ ማእከላይ ስም",
        slname:"ናይ ዋሕስ መወዳእታ ስም",
        szone:"ዞባ ዋሕስ",
        sworeda:"ወረዳ ዋሕስ",
        skebelle:" ዋሕስ ቀበሌ",
        spnumber:"ቁጽሪ ስልኪ ዋሕስ",
        agree:"እዚ ዝስዕብ ይርድኣኒን ይሰማማዕን:",
        frequest:"ውዕላትን ፖሊሲታትን ንሕቶ ፈንድ",
        funder:"ውዕላትን ፖሊሲታትን ንመወልቲ",
        cname:"ስም ትካል",
        ceo:"ዋና ኣካያዲ ስራሕ",
        osector:"ዘፈር ግለጽ"

      },
      company: {
        registration: 'ድርጅት ምዝገባ',
        registration_id: 'የምዝገባ መለያ',
        name: 'ስም ትካል',
        sector: 'ሴክተር'
      },
      funding: {
        label: 'ብሃንቀውታ እየ ዝጽበዮ'
      },
      rules: {
        require:"ኣድላዪ",
        agree:"ንክትቅፅሉ ክትሰማምዑ ኣለኩም።",
        pn:'ኣወንታዊ ቁጽሪ ክኸውን ኣለዎ።',
        e:'ኢ-መይል ቅኑዕ ክኸውን ኣለዎ።',
        p:'ቁጽሪ ተሌፎን ቅኑዕ ክኸውን ኣለዎ።'
      },
      msg:{
        scs:"ምዝገባ ዕዉት!፡ ኣብ ቀረባ እዋን ምሳኻትኩም ክንራኸብ ኢና።",
        e:"ኢመይል ተመዝጊቡ ኣሎ። ካልእ ኢመይል ኣድራሻ ተጠቐሙ።",
        err:"ፀገም ኔትወርክ አጋጢሙሎ። በይዝኦም  ደጊሞም ይሞክሩ።",
        
      }
    }
  };
  