import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createI18n } from 'vue-i18n';
import { messages } from './i18n';



// Create the i18n instance
const i18n = createI18n({
    legacy:false,
    locale: 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages

});

// Use the i18n instance with createApp
loadFonts()

createApp(App)
  .use(vuetify)
  .use(i18n)
  .mount('#app')
