<template>
  <v-app>

    <!-- Navbar -->
    <v-app-bar app color="primary" dark>
      <!-- <v-app-bar-nav-icon v-if="isMobile" @click="drawer = !drawer"></v-app-bar-nav-icon> -->
      <v-col cols="auto" v-if="isMobile">
        
          <div style="display: flex; align-items: center; flex-flow: row; gap: 10px;  justify-items: center; margin-top:0%; align-items:center">
            <v-icon>mdi-translate</v-icon>

            <v-select
              v-model="currentLanguage"
              :items="languages"
              class="ml-auto"
              style="margin-top: 30%;"
              outlined
              dense
            ></v-select>
          </div>
          </v-col> 
      <v-container fluid>
        <v-row align="center">
          <v-col cols="auto">
            <v-toolbar-title class="white--text text-center text-lg-start pa-4 my-5">
              <span class="hidden-sm-and-down">{{ $t('app.title') }}</span>
              <span class="hidden-md-and-up" :class="isMobile ? 'text-caption' : 'text-h6'">
                {{$t('app.title')}}
              </span> 
            </v-toolbar-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>

          </v-col>

        <v-col cols="auto">
          <div style="display: flex; align-items: center; flex-flow: row; gap: 10px;  justify-items: center; margin-top:0%; align-items:center">
            <v-icon>mdi-translate</v-icon>

            <v-select
              v-model="currentLanguage"
              :items="languages"
              class="ml-auto"
              style="margin-top: 30%;"
              outlined
              dense
            ></v-select>
          </div>
          </v-col> 
          <!-- Spacer -->
        </v-row>
      </v-container>
      <!-- About Link -->
      <!-- <v-btn text outlined class="white--text hidden-sm-and-down">{{ $t('app.about') }}</v-btn> -->
      <!-- Contact Link -->
      <!-- <v-btn text outlined class="white--text hidden-sm-and-down">{{ $t('app.contact') }}</v-btn> -->

      <!-- Language Switcher -->

     
     
    </v-app-bar>

      <!-- Drawer for Mobile -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <!-- <v-list>
        <v-list-item v-for="(item, index) in navItems" :key="index" link>
          <v-list-item-title>{{ item }}</v-list-item-title>
          
        </v-list-item>
      </v-list> -->
     
    </v-navigation-drawer>



    <v-main>
        <v-container>
          <v-card elevation="12" class="pa-4 my-5 card-shadow">
            <v-card-text>
              <h2 class="text-center">{{$t('registration.welcome')}}</h2>
              <h3 class="text-center">{{ $t('registration.desc') }}</h3>
            </v-card-text>
          </v-card>
        </v-container>
      <v-container>
        <v-card elevation="12" class="pa-4 my-5 card-shadow">
          <v-card-title class=" bg-primary lighten-2 py-3">
            {{ $t('registration.title') }}
          </v-card-title>
          <v-card-text>
            <v-radio-group v-model="registration_option" row class="mb-4" style="color: black;">
              <v-radio :label="$t('registration.personal')" value="personal"></v-radio>
              <v-radio :label="$t('registration.company')" value="company"></v-radio>
            </v-radio-group>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="funding_option"
                    :items="fundingOptions"
                    :label="$t('personal.look')"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
          
          <!-- Personal Registration Form -->
          <v-container v-if="registration_option === 'personal'">
           
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.first_name" :label="$t('personal.fname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.middle_name" :label="$t('personal.mname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.last_name" :label="$t('personal.lname')" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.age" :label="$t('personal.age')" type="number" :rules="[rules.required, rules.age]"></v-text-field>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-text-field v-model="personal.dob" label="Date of Birth" type="date" :rules="[rules.required]"></v-text-field>
              </v-col> -->
              <v-col cols="12" md="4">
                <v-select v-model="personal.gender" :items=genders :label="$t('personal.gender')" :rules="[rules.required]"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.address_zone" :label="$t('personal.zadress')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.address_woreda" :label="$t('personal.waddress')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">  
                <v-text-field v-model="personal.address_kebelle" :label="$t('personal.kaddress')" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="personal.phone_number" :label="$t('personal.pnumber')" :rules="[rules.required, rules.phone]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="personal.email" :label="$t('personal.email')" :rules="[rules.required, rules.email]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="personal.photo" :label="$t('personal.photo')" :rules="[rules.required]" accept="image/*"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="personal.doc" :label="$t('personal.sdoc')" :rules="[rules.required]" accept=".pdf,.doc,.docx"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select v-model="personal.sector_for_mutual_fund" :items="sectors" :label="$t('personal.sector')" :rules="[rules.required]"></v-select>
                <v-text-field v-if="personal.sector_for_mutual_fund === $t('dd.o')" v-model="personal.other_sector" :label="$t('personal.osector')" :rules="[rules.required]"></v-text-field>              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.surety_first_name" :label="$t('personal.sfname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.surety_middle_name" :label="$t('personal.smname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.surety_last_name" :label="$t('personal.slname')" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.surety_zone" :label="$t('personal.zadress')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.surety_woreda" :label="$t('personal.waddress')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="personal.surety_kebelle" :label="$t('personal.kaddress')" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="personal.surety_phone_number" :label="$t('personal.spnumber')" :rules="[rules.required, rules.phone]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <!-- Company Registration Form -->
          <v-container v-if="registration_option === 'company'">
            
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.name" :label="$t('personal.cname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.sector" :label="$t('personal.sector')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.company_ceo" :label="$t('personal.ceo')" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="company.phone_number" :label="$t('personal.pnumber')" :rules="[rules.required, rules.phone]"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="company.email" :label="$t('personal.email')" :rules="[rules.required, rules.email]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="company.photo" :label="$t('personal.photo')" :rules="[rules.required]" accept="image/*"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="company.doc" :label="$t('personal.sdoc')" :rules="[rules.required]" accept=".pdf,.doc,.docx"></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select v-model="company.sector_for_mutual_fund" :items="sectors" :label="$t('personal.sector')" :rules="[rules.required]"></v-select>
                <v-text-field v-if="company.sector_for_mutual_fund === 'Other'" v-model="company.other_sector" label="Specify Sector" :rules="[rules.required]"></v-text-field>
              </v-col>              
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.surety_first_name" :label="$t('personal.sfname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.surety_middle_name" :label="$t('personal.smname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.surety_last_name" :label="$t('personal.slname')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.surety_zone" :label="$t('personal.zadress')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.surety_woreda" :label="$t('personal.waddress')" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field v-model="company.surety_kebelle" :label="$t('personal.kaddress')" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="company.surety_phone_number" :label="$t('personal.spnumber')" :rules="[rules.required, rules.phone]"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
             <!-- Checkbox for agreeing to terms and policies -->
             <v-row>
  <v-col cols="12">
    <v-checkbox
      v-model="agree"
      :rules="[v => !!v || this.$t('rules.agree')]"
      style="color: black;">
      <template v-slot:label>
        <div>
          <b>{{$t('personal.agree')}}</b>
          <br>
          <a href="#" @click.prevent="openTerms2" style="color: var(--secondary-color); text-decoration: underline; display: block; margin-top: 8px;">{{$t('personal.frequest')}}</a>
          <a href="#" @click.prevent="openTerms1" style="color: var(--secondary-color); text-decoration: underline; display: block; margin-top: 8px;">{{$t('personal.funder')}}</a>
        </div>
      </template>
    </v-checkbox>
  </v-col>
</v-row>


            
          <v-row>
            <v-col cols="12" class="text-center">
              <!-- <v-btn :disabled="!valid || !agree" color="primary" class="mr-4" @click="submit">{{ $t('app.submit') }}</v-btn> -->
              <v-btn :disabled="!valid" color="primary" class="mr-4" @click="submit">{{ $t('app.submit') }}</v-btn>
                  <v-btn color="primary" @click="reset" class="my-3">{{ $t('app.reset') }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>



    
  </v-container>

  <template>
  <v-app>
    <v-overlay v-model="loading">
      <v-progress-circular indeterminate color="primary" location="center"></v-progress-circular>
    </v-overlay>
  
    <v-snackbar v-model="snackbarSuccess" :timeout="3000" top color="success">
      {{ successMessage }}
    </v-snackbar>

    <v-snackbar v-model="snackbarError" :timeout="3000" top color="error">
      {{ errorMessage }}
    </v-snackbar>
  </v-app>
</template>
</v-main>

  <!-- Footer -->
  <v-footer color="primary" app dark>
      <span class="white--text">&copy; 2024 {{ $t('app.title') }}</span>
    </v-footer>
  </v-app>
</template>

<script>
//import axios from 'axios';
import axios from 'axios';
// import { useHtml2pdf } from 'vue-html2pdf';


export default {
  data() {
    return {
      registration_option: 'personal',
      valid: false,
      agree: false,
      loading: false, // New loading data property
      snackbarSuccess: false, // Snackbar for success message
      snackbarError: false, // Snackbar for error message
      successMessage: '', // Success message text
      errorMessage: '', // Error message text

      uniqueId: '',
      drawer: false,
      // fundingOptions: [this.$t('dd.fund'), this.$t('dd.tofund')], // Options for the v-select
      navItems: ['About', 'Contact us'],
      // genders: [this.$t('dd.male'), this.$t('female')],
      // sectors: [this.$t('dd.mining'), this.$t('dd.agre'), this.$t('dd.water'), this.$t('dd.const'),
      //  this.$t('dd.dm'), this.$t('dd.v'),this.$t('dd.ap'),this.$t('dd.re'),this.$t('dd.e'), this.$t('dd.f'),
      //   this.$t('dd.t'), this.$t('dd.r'), this.$t('dd.m'), this.$t('dd.o')],
      funding_option: '',
      personal: {
        first_name: '',
        middle_name: '',
        last_name: '',
        photo: '',
        age: '',
        dob: '',
        gender: '',
        address_zone: '',
        address_woreda: '',
        address_kebelle: '',
        phone_number: '',
        email: '',
        doc: '',
        sector_for_mutual_fund: '',
        surety_first_name: '',
        surety_middle_name: '',
        surety_last_name: '',
        surety_zone: '',
        surety_woreda: '',
        surety_kebelle: '',
        surety_phone_number: '',
      },
      company: {
        name: '',
        sector: '',
        company_ceo: '',
        email: '',
        phone_number: '',
        photo: null,
        address_zone: '',
        address_woreda: '',
        address_kebelle: '',
        doc: null,
        sector_for_mutual_fund: '',
        surety_first_name: '',
        surety_middle_name: '',
        surety_last_name: '',
        surety_zone: '',
        surety_woreda: '',
        surety_kebelle: '',
        surety_phone_number: '',
      },
      rules: {
        required: value => !!value || this.$t('rules.require'),
        age: value => (value && value > 0) || this.$t('rules.pn'),
        email: value => /.+@.+\..+/.test(value) || this.$t('rules.e'),
        phone: value => /^\d{10}$/.test(value) || this.$t('rules.p'),
      },
      currentLanguage: 'EN',
      languages: ['EN', 'ትግ']
    };
  },
  computed: {
    fundingOptions() {
      return [
        this.$t('dd.fund'),
        this.$t('dd.tofund')
      ];
    },
    genders() {
      return [
        this.$t('dd.male'),
        this.$t('dd.female')
      ];
    },
    sectors() {
      return [
        this.$t('dd.mining'),
        this.$t('dd.agre'),
        this.$t('dd.water'),
        this.$t('dd.const'),
        this.$t('dd.dm'),
        this.$t('dd.v'),
        this.$t('dd.ap'),
        this.$t('dd.re'),
        this.$t('dd.e'),
        this.$t('dd.f'),
        this.$t('dd.t'),
        this.$t('dd.r'),
        this.$t('dd.m'),
        this.$t('dd.o')
      ];
    },
    isMobile() {
      return window.innerWidth <= 600;
    },
  },
  watch: {
    isMobile(value) {
      this.drawer = value; // Always show drawer on mobile
    },
    currentLanguage(newLang) {
      this.$i18n.locale = newLang;
    },

  },
  mounted() {
    // this.$i18n.locale = this.currentLanguage;
    this.changeLanguage();
  },
  methods: {
    openTerms1() {
      window.open(`${process.env.BASE_URL}1.pdf`, '_blank');
    },
    openTerms2() {
      window.open(`${process.env.BASE_URL}2.pdf`, '_blank');
    },
    changeLanguage() {
      this.$i18n.locale = this.currentLanguage;
    },
    reset() {
      this.registration_option = 'personal';
      this.agree = false;
      this.valid = false;
      this.funding_option = ''; // Reset the funding option
      this.personal = {
        first_name: '',
        middle_name: '',
        last_name: '',
        age: '',
        gender: '',
        address_zone: '',
        address_woreda: '',
        address_kebelle: '',
        phone_number: '',
        email: '',
        photo: null,
        doc: null,
        sector_for_mutual_fund: '',
        other_sector: '',
        surety_first_name: '',
        surety_middle_name: '',
        surety_last_name: '',
        surety_zone: '',
        surety_woreda: '',
        surety_kebelle: '',
        surety_phone_number: '',
      };
      this.company = {
        name: '',
        sector: '',
        company_ceo: '',
        phone_number: '',
        email: '',
        photo: null,
        doc: null,
        sector_for_mutual_fund: '',
        other_sector: '',
        surety_first_name: '',
        surety_middle_name: '',
        surety_last_name: '',
      };
    },
    submit() {
  if (this.$refs.form.validate() && this.agree) {
    console.log('Form validated and user agreed to terms');
    const data = this.registration_option === 'personal' ? this.personal : this.company;
    const formData = new FormData();
    console.log("DATA:")
    console.log(data)
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    formData.append('registration_option', this.registration_option);
    formData.append('funding_option', this.funding_option);

    console.log('Form data prepared', formData);

    try {
      this.loading = true; // Show loading spinner

      axios.post('https://mfrapi.goldenideabank.com/api/register', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        console.log('Response received:', response.data);

        if (response.data) {
          this.uniqueId = response.data.uniqueid;
          this.successMessage = this.$t('msg.scs');
          this.snackbarSuccess = true;

          setTimeout(() => {
            this.preparePrintContent();
          }, 2000);
        } else {
          this.errorMessage = this.$t('msg.e');
          this.snackbarError = true;
        }
      }).catch((error) => {
        console.log('Error during API call');
        console.error('Error details:', {
                message: error.message,
                name: error.name,
                code: error.code,
                config: error.config,
                stack: error.stack,
                request: error.request,
                response: error.response
    });
        if (error.response && error.response.data && error.response.data.message) {
          this.errorMessage = error.response.data.message;
        } else {
          this.errorMessage = this.$t('msg.err');
        }
        this.snackbarError = true;
      });
    } catch (error) {
      console.error('Unexpected error during form submission', error);
      this.errorMessage = this.$t('msg.err');
      this.snackbarError = true;
    } finally {
      this.loading = false; // Hide loading spinner
    }
  }
},

//Transfer Br1,350.00 to 103065291 and submit the Form Below

preparePrintContent() {
  this.$nextTick(() => {
    const full_name = this.registration_option === 'personal' ? this.personal.first_name +" "+this.personal.last_name : this.company.name;
    full_name.toUpperCase();
    const golden_id = "GOLDEN-" + this.uniqueId;
    const company_name = "GOLDEN International Business and Investment GROUP";
    
    const printWindow = window.open('', '');
    if (!printWindow) {
      console.error('Popup window blocked. Please allow popups for this website to print.');
      return;
    }
    new Promise(resolve => resolve(printWindow))
      .then(printWindow => {
      printWindow.document.write('<html>');
      printWindow.document.write('<head>'); 
      printWindow.document.write('<link rel="stylesheet" href="./id.css">');    // Update path if needed
      printWindow.document.write('</head>');
      printWindow.document.write('<body>');
      
      printWindow.document.write(`
        <div id="card">
          <header class="header">
            <img src="./logo.png" alt="Logo">  <div class="header-text">
              <h3>${company_name}</h3>
            </div>
          </header>
          <div class="line"></div>
          <div class="detail">
            <div class="text">
              <h3>${full_name}</h3>
              <h5><b>ID NUMBER: </b>${golden_id}</h5>
            </div>
            <div class="membership">
            </div>   
          </div>
          <div class="footer">
            <small>GIBIG Mutual Fund MEMBERSHIP ID CARD</small>
          </div>
        </div>
      `);
      
      printWindow.document.write('</body>');
      printWindow.document.write('<base href="' + location.origin + location.pathname + '">');
      printWindow.document.write('</html>');
      
      printWindow.document.close();
 

    // Ensure all resources are loaded before printing
    const imagePromises = [];
    const images = printWindow.document.querySelectorAll('img');
    images.forEach(img => imagePromises.push(new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    })));

    Promise.all(imagePromises).then(() => {
      printWindow.print();
      printWindow.close();
    }).catch(() => {
      console.error('Failed to load image resources');
      // Handle potential image loading errors (optional)
    });
  });
  });
},

// async preparePrintContent() {

//   const full_name = this.registration_option === 'personal' ? this.personal.first_name + " " + this.personal.last_name : this.company.name;
//   full_name.toUpperCase();
//   const golden_id = "GOLDEN-" + this.uniqueId;
//   const company_name = "GOLDEN International Business and Investment GROUP";

//   const printContent = `
//     <div id="card">
//       <header class="header">
//         <img src="./logo.png" alt="Logo">
//         <div class="header-text">
//           <h3>${company_name}</h3>
//         </div>
//       </header>
//       <div class="line"></div>
//       <div class="detail">
//         <div class="text">
//           <h3>${full_name}</h3>
//           <h5><b>ID NUMBER: </b>${golden_id}</h5>
//         </div>
//         <div class="membership">
//         </div>   
//       </div>
//       <div class="footer">
//         <small>GIBIG Mutual Fund MEMBERSHIP ID CARD</small>
//       </div>
//     </div>
//   `;

//   const { pdf } = await useHtml2pdf();
//   pdf.from(printContent);

//   // Optional: Set PDF options like margins, headers, footers
//   // pdf.options.margins = { top: 20, left: 20, right: 20, bottom: 20 };

//   // Choose between download or print functionality
//   pdf.save(); // This will trigger a download of the PDF
//   // pdf.print(); // This will print the PDF directly (if browser supports)

//   // Handle potential errors during PDF generation (optional)
//   pdf.on('rendered', () => console.log('PDF generated successfully'));
//   pdf.on('error', (error) => console.error('Error generating PDF:', error));
// }


    
  }

 
}
</script>

<style scoped>
.card-shadow {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 4px 10px rgba(0, 0, 0, 0.12);
}
.v-card {
  margin: 20px auto;
  max-width: 900px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px teal;
  background: #f8f9fa;
}

.v-card-title {
  border-radius: 15px 15px 0 0;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  background-color: #1976d2;
  color: white;
}

.v-btn {
  /* margin-top: 0px; */
  font-size: 16px;
  padding: 10px 30px;
  background-color: #1976d2;
  color: white;
  /* border-radius: 5px; */
  /* transition: background-color 0.3s; */
}

.v-btn:hover {
  background-color: #115293;
}

.v-text-field,
.v-file-input,
.v-select {
  margin-bottom: 20px;
}

.v-radio-group {
  margin: 20px 0;
}

.v-container {
  padding: 0 !important;
}
.v-app-bar {
    background-color: #1976d2;
  }

  .v-toolbar-title {
    font-weight: bold;
    font-size: 24px;  
  }

  /* Hide buttons on small screens */
@media only screen and (max-width: 600px) {
  .hidden-sm-and-down {
    display: none !important;
  }
}
.btn-gap {
  margin-right: 10px;
}

.v-navigation-drawer {
  width: 250px;
}

.v-radio__label {
  font-weight: normal;
}

/* Increase spacing between form elements */
.v-form {
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .v-toolbar-title {
    font-size: 1.2rem;
  }
}

:root {
  --primary-color: #007bff;
  --secondary-color: #ffc107;
}

.v-app-bar {
  background-color: var(--primary-color);
}

.v-card-title {
  color: var(--secondary-color);
}
</style>
